import { Box, Button, TextField, Select, MenuItem, Typography, useTheme  } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "./Header";  
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { tokens } from "../theme";
import { useEffect, useState } from "react";

const Form = () => {

  let { id } = useParams();

  const calendarIconStyle = {
    fill: '#FFFFFF', // Change the calendar icon color
  };

  const getAuthToken = () => {
    return JSON.parse(localStorage.getItem('authToken'));
  };
  const inputStyle = { WebkitBoxShadow: "0 0 0 1000px #191a1a  inset" };
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [coupons, setCoupons] = useState([]);
  const [discound, setDiscound] = useState(true);

  // useEffect(() => {
  //   // Replace 'your-api-endpoint' with the actual API endpoint.
  //   axios.get(`https://api-dev-ticket.wecodelife.com/v1/data/dashboard/coupon-details/${id}/`,{
  //     headers: {
  //       'Token': `${getAuthToken()}`,
  //       'Authorization': `Token ${getAuthToken()}`,
  //       'Content-Type': 'application/json',
  //     },
  //   })
  //     .then((response) => {
  //       console.log(response.data);
  //       setCoupons([response.data.data]);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching Coupons:', error);
  //     });
  // }, []);



  function convertCustomFormatToISO8601(customFormat) {
    // Assuming customFormat is in the format "YYYY-MM-DDhh:mm"
    
    const year = customFormat.slice(0, 4);
    const month = customFormat.slice(5, 7);
    const day = customFormat.slice(8, 10);
    const hour = customFormat.slice(10, 12);
    const minute = customFormat.slice(13, 15);
    
    // Create a Date object with the parsed components
    const date = new Date(year, month - 1, day, hour, minute);
    
    // Convert the Date object to ISO 8601 format
    const iso8601Date = date.toISOString();
    
    return iso8601Date;
  }



  const handleSuccess = () => {
    // Display a success toast
    toast.success('Coupon Added Successfully', {
      position: 'top-right',
      autoClose: 1000,
    });
    
  };


  const isNonMobile = useMediaQuery("(min-width:600px)");

  const handleFormSubmit = async (values,{ resetForm }) => {
    // console.log(getAuthToken());
    try {
      const clonedValues = JSON.parse(JSON.stringify(values));
      clonedValues.discount_value = clonedValues.discount == "" ? null : parseInt(clonedValues.discount);
      delete clonedValues.discount;
      clonedValues.discount_percentage = clonedValues.percentage == "" ? null : clonedValues.percentage;
      delete clonedValues.percentage;
      clonedValues.valid_from = convertCustomFormatToISO8601(clonedValues.fromdate + clonedValues.fromtime);
      delete clonedValues.fromdate;
      delete clonedValues.fromtime;
      clonedValues.valid_to = convertCustomFormatToISO8601(clonedValues.todate + clonedValues.totime);
      delete clonedValues.todate;
      delete clonedValues.totime;
      clonedValues.event = parseInt(id);
      clonedValues.token = getAuthToken();
      clonedValues.is_active = true;
      clonedValues.active = true;


      // console.log(clonedValues);
      const response = await axios.post('https://fourband-app-2n2vh.ondigitalocean.app/v1/data/dashboard/create-coupon/', clonedValues, {
        headers: {
          'Token': `${getAuthToken()}`,
          'Authorization': `Token ${getAuthToken()}`,
          'Content-Type': 'application/json',
        },
      });
  
      // Check the response status code for success (e.g., HTTP 200)
      if (response.status === 200 || response.status === 201) {
        // console.log('Response:', response.data);
        handleSuccess()
      } else {
        // Handle unexpected response status codes
        console.error('Unexpected response:', response.status);
        alert('An unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle network or request errors
      console.error('Error:', error);
  
      // Check if the error is due to unauthorized access (401)
      if (error.response && error.response.status === 401) {
        alert('Unauthorized access. Please log in again.');
        // You might want to redirect the user to the login page here
        window.location='/';
      } else {
        // Handle other errors
        alert('An error occurred. Please try again later.');
      }
    }
    resetForm();
  };
  
  return (
    <Box m="20px" className="event_coupon" style={{position:"relative"}}>
      <Header title="ADD COUPON" subtitle="Create a New coupon" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="20px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
               <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Code"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.code}
                inputProps={{ style: inputStyle }}
                name="code"
                error={!!touched.code && !!errors.code}
                helperText={touched.code && errors.code}
                sx={{ gridColumn: "span 4" }}
                InputLabelProps={{
                  style: {
                    color: colors.color="#FFFFFF" // Change the color to your desired color
                  },
                }}
              />
              <span style={{display:"flex",minWidth:"300px"}}>
                <label style={{ cursor: "pointer" }} for="dvalue">Discount Value</label> <input type="radio" checked={discound} onChange={() => setDiscound(true)} name="disc" id="dvalue" /> &nbsp;&nbsp;&nbsp;&nbsp;
                <label style={{ cursor: "pointer" }} for="dpercentage">Discount Percentage</label> <input type="radio" checked={!discound} onChange={() => setDiscound(false)} name="disc" id="dpercentage" />
              </span>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                style={{display:`${discound ? 'block' : 'none'}`}}
                label="Discount value"
                onBlur={handleBlur}
                onChange={handleChange}
                inputProps={{ style: inputStyle }}
                value={values.discount}
                name="discount"
                error={!!touched.discount && !!errors.discount}
                helperText={touched.discount && errors.discount}
                sx={{ gridColumn: "span 4" }}
                InputLabelProps={{
                  style: {
                    color: colors.color="#FFFFFF" // Change the color to your desired color
                  },
                }}
              />
               <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Discount Percentage"
                onBlur={handleBlur}
                style={{display:`${discound ? 'none' : 'block'}`}}
                onChange={handleChange}
                value={values.percentage}
                inputProps={{ style: inputStyle }}
                name="percentage"
                error={!!touched.percentage && !!errors.percentage}
                helperText={touched.percentage && errors.percentage}
                sx={{ gridColumn: "span 4" }}
                InputLabelProps={{
                  style: {
                    color: colors.color="#FFFFFF" // Change the color to your desired color
                  },
                }}
              />
                
               <TextField
                fullWidth
                variant="filled"
                type="date"
                label="From Date"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.fromdate}
                name="fromdate"
                inputProps={{ style: inputStyle }}
                error={!!touched.fromdate && !!errors.fromdate}
                helperText={touched.fromdate && errors.fromdate}
                // sx={{ gridColumn: "span 4" }}
                sx={{
                  gridColumn: "span 4",
                  "& input[type='date']::-webkit-calendar-picker-indicator": {
                    filter: "invert(1)", // Change color as needed
                  },
                  "& label": {
                    color: colors.color="#FFFFFF", // Change label color
                    transform: "translate(0, 1.5px)", // Move the label up slightly
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: colors.color="#FFFFFF", // Change the color to your desired color
                     transform: 'translate(0, 1.5px)', // Move the label up slightly
                  },
                }}
                
              />
              <TextField
                fullWidth
                variant="filled"
                type="time"
                label="From Time"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.fromtime}
                name="fromtime"
                inputProps={{ style: inputStyle }}
                error={!!touched.fromtime && !!errors.fromtime}
                helperText={touched.fromtime && errors.fromtime}
                sx={{
                  gridColumn: "span 4",
                  "& input[type='time']::-webkit-calendar-picker-indicator": {
                    filter: "invert(1)", // Change color as needed
                  },
                  "& label": {
                    color: colors.color="#FFFFFF", // Change label color
                    transform: "translate(0, 1.5px)", // Move the label up slightly
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: colors.color="#FFFFFF", // Change the color to your desired color
                    transform: 'translate(0, 1.5px)', // Move the label up slightly
                  },
                }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="date"
                label="To Date"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.todate}
                name="todate"
                inputProps={{ style: inputStyle }}
                error={!!touched.todate && !!errors.todate}
                helperText={touched.todate && errors.todate}
                sx={{
                  gridColumn: "span 4",
                  "& input[type='date']::-webkit-calendar-picker-indicator": {
                    filter: "invert(1)", // Change color as needed
                  },
                  "& label": {
                    color: colors.color="#FFFFFF", // Change label color
                    transform: "translate(0, 1.5px)", // Move the label up slightly
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: colors.color="#FFFFFF", // Change the color to your desired color
                    transform: 'translate(0, 1.5px)', // Move the label up slightly
                  },
                }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="time"
                label="To Time"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.totime}
                name="totime"
                inputProps={{ style: inputStyle }}
                error={!!touched.totime && !!errors.totime}
                helperText={touched.totime && errors.totime}
                sx={{
                  gridColumn: "span 4",
                  "& input[type='time']::-webkit-calendar-picker-indicator": {
                    filter: "invert(1)", // Change color as needed
                  },
                  "& label": {
                    color: colors.color="#FFFFFF", // Change label color
                    transform: "translate(0, 1.5px)", // Move the label up slightly
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: colors.color="#FFFFFF", // Change the color to your desired color
                    
                  },
                }}
              />
               <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Limit"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.limit}
                name="limit"
                inputProps={{ style: inputStyle }}
                error={!!touched.limit && !!errors.limit}
                helperText={touched.limit && errors.limit}
                sx={{ gridColumn: "span 4" }}
                InputLabelProps={{
                  style: {
                    color: colors.color="#FFFFFF" // Change the color to your desired color
                  },
                }}
              />

            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained" onClick={handleSubmit}>
                Add coupon
              </Button>
            </Box>

            <br />
            <br />

          </form>
        )}
      </Formik>

      {/* <div style={{ display: 'flex', flexDirection: 'column',gap:'10px',width:"100%",margin:"0px",padding:"0px",position:"absolute",left:"-50px",right:'0px' }}>
          {coupons.map((coupon)=>(
            <CouponCard
            key={coupon.id}
            code={coupon.code}
            percentage={coupon.discount_percentage}
            limit={coupon.limit}
            from_date={coupon.valid_from}
            to_date={coupon.valid_to}
            id={coupon.id}
         icon={
           <PartyMode 
           sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
           />
          }
          />
          
          ))}
          </div> */}
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  code: yup.string().required("Code is required"),
  discount: yup
  .string()
  .test('is-exclusive', 'Either Discount value or Percentage is required', function (value) {
    const percentage = this.parent.percentage;
    if (!value && !percentage) {
      return this.createError({ path: 'discount', message: 'Either Discount value or Percentage is required' });
    }
    return true;
  }),
percentage: yup
  .string()
  .test('is-exclusive', 'Either Discount value or Percentage is required', function (value) {
    const discount = this.parent.discount;
    if (!value && !discount) {
      return this.createError({ path: 'percentage', message: 'Either Discount value or Percentage is required' });
    }
    return true;
  }),
  fromdate: yup.string().required("From Date is required"),
  fromtime: yup.string().required("From Time is required"),
  todate: yup.string().required("To Date is required"),
  totime: yup.string().required("To Time is required"),
  limit: yup.string().required("Limit is required"),
  
});
const initialValues = {
  code: "",
  discount: "",
  percentage:"",
  fromdate:"",
  fromtime:"",
  todate:"",
  totime:"",
  limit:"",
};

export default Form; 