import { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/checker";
import Invoices from "./scenes/invoices";
import Form from "./scenes/form";
import AddEvents from "./components/AddEvents"; 
import Addcategory from "./components/Addcategory";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Calendar from "./scenes/calendar/calendar";
import LoginPage from "./components/LoginPage";
import Addcoupon from "./components/Addcoupon"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for styling
import EditEvents from "./components/EditEvents";
import ViewCoupons from "./scenes/coupons/ViewCoupons";
import EditCoupon from "./components/EditCoupon";
function App() {

  const isTokenPresent = () => {
    const token = localStorage.getItem('authToken');
    return !!token;
  };

  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  return (
    <ColorModeContext.Provider value={colorMode}>

      
        <ToastContainer />
        {window.location.pathname === '/' ? (
          <Routes>
          <Route path="/" element={<LoginPage />} />
          </Routes>
        ) : (
          <>
          {isTokenPresent() ?(
            <ThemeProvider theme={theme}>
              <CssBaseline/>
              <div className="app">
                <Sidebar isSidebar={isSidebar} />
                <main className="content">
                  <Topbar setIsSidebar={setIsSidebar} />
                  <Routes>
                    <Route path="/home" element={<Dashboard />} />
                    <Route path="/view_checker" element={<Team />}/>
                    <Route path="/invoices/:id/:identifier" element={<Invoices />} />
                    <Route path="/form" element={<Form />} />
                    <Route path="/add_event" element={<AddEvents />} />
                    <Route path="/edit_event/:id/:identifier" element={<EditEvents />} />
                    <Route path="/edit_coupon/:id" element={<EditCoupon />} />
                    <Route path="/add_category" element={<Addcategory />} />
                    <Route path="/add_coupon" element={<Addcoupon/>} />
                    <Route path="/view_coupons" element={<ViewCoupons/>} />
                    <Route path="/add_checker" element={<Form/>} />
                    <Route path="/calendar" element={<Calendar />} />
                  </Routes>
                </main>
              </div>
            </ThemeProvider>
            ):(
              <Routes>
              <Route path="/" element={<LoginPage />} />
              <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            )}
          </>
        )}
     
    </ColorModeContext.Provider>
  );
  
}

export default App;
