import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import remove from '../components/assets/remove.png'
import edit from '../components/assets/edit.png'
import { Link } from "react-router-dom";

const CategoryCard = ({ category, desc, deleteCategory, id }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode)

  return (
    <Box backgroundColor={colors.color=" #1A0F0F "} width="100%"  border="9px solid  #1A0F0F ">
      <Box display="flex" justifyContent="space-between">
        <Box>
        <Typography
            variant="h5"
            fontWeight="bold"
            sx={{color: colors.whiteAccent[100] }}
          >
            {category}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" mt="2px">
        <Typography variant="h5" sx={{ color: colors.whiteAccent[100]}}>
          {desc}
        </Typography>
        <Box display="flex" justifyContent="space-between" gap="5px" mb="5px">
        {/* <a href="/add_checker"><button className="assign_btn">Assign Checker</button></a> */}
       
        <button onClick={()=> deleteCategory(id)} className="remove_btn"><img  src={remove} alt="" /></button>
        </Box>
      </Box>

    </Box>
  );
};

export default CategoryCard;
